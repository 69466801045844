import Cookies from 'lib/js-cookie';
import { getUserData } from 'apis/profile';

const events = {
  PAGE_VIEW: 'pageview',
  LOGIN: 'login',
  SUBSCRIPTION_STATUS: 'subscriptionStatus',
  PODCAST_CREATION: 'podcastCreation',
  PLAYER_STATUS: 'audioPlayer',
  USER: 'user',
};

// @ts-ignore
const dataLayer = (): any => window?.dataLayer || [];

const sendUserData = async () => {
  const t = Cookies.get('token');
  if (t) {
    const response = await getUserData();
    const language = Cookies.get('language');
    dataLayer().push({
      event: events.USER,
      // @ts-ignore
      userData: { ...response?.data, language },
    });
  } else {
    dataLayer().push({
      event: events.USER,
      userData: undefined,
    });
  }
  return Promise.resolve(true);
};

export const gtmEvents = {
  trackPage: (page: pageViewAttr) => {
    dataLayer().push({ event: events.PAGE_VIEW, page });
  },
  login: (data: login) => {
    dataLayer().push({ event: events.LOGIN, ...data });
  },
  subscriptionStatus: async (data: subscriptionStatus) => {
    if (data.status === 'finished') {
      await sendUserData();
    }
    dataLayer().push({
      event: events.SUBSCRIPTION_STATUS,
      ...data,
    });
  },
  podcastCreation: async (data: podcastCreation) => {
    if (data.status === 'finished') {
      await sendUserData();
    }
    dataLayer().push({ event: events.PODCAST_CREATION, ...data });
  },
  playerStatus: (data: player) => {
    dataLayer().push({ event: events.PLAYER_STATUS, audioPlayer: data });
  },
  userData: async () => sendUserData(),
};

interface userData {
  podcast_created: number;
  podcast_subscribed: number;
  signup_date: Date;
  last_accessed: Date;
  number_of_episodes_uploaded: number;
  last_episode_date: Date;
  number_of_subscribers: number;
  mrr: number;
  money_in_wallet: number;
  money_requested: number | null;
  money_in_total: number | null;
  country: string;
  user_id: number;
  has_iban_number: any;
  has_tax_number: any;
}

interface podcastCreation {
  status: 'started' | 'creating' | 'finished';
  podcastData: podcast | any;
}

interface player {
  status: 'playing' | 'stopped';
  episodeData: episode | any;
}

interface episode {
  url?: string;
  episode_name?: string;
  hosted_by: string;
  cover?: string;
  podcast_name: string;
  podcast_slug: string;
  description?: string;
  id?: number;
}
interface podcast {
  id?: string;
  slug?: string;
  language?: string;
  is_featured?: string;
  price?: number;
  podcaster?: number;
}

interface subscriptionStatus {
  status: 'started' | 'paying' | 'finished';
  podcast: podcast | any;
}

interface login {
  method: 'manual' | 'google' | 'facebook';
  userData: any;
}

interface pageViewAttr {
  url: string;
  title: string;
}
