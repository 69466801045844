import React, { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import UserContext from 'context/UserContext';
import useLang from 'context/LanguageContext';
import { translate as t } from 'utils/helpers';
import styles from './Popup.module.scss';

const SuccessMessagePopup = () => {
  const { lang } = useLang();
  const [userData, setUserData] = useContext(UserContext);
  const hideModal = () => {
    if (userData?.closeActionCallback) {
      userData?.closeActionCallback();
    }
    setUserData((prevState) => ({ ...prevState, showModal: false }));
  };
  return (
    <Modal
      className={styles.modalParent}
      show={userData.showModal}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={hideModal}>
      <Modal.Body className={styles.modalBody}>
        <h4 className={styles.modalTitle}>{userData.title}</h4>
        {userData?.showIcon !== false && (
          <img
            className={styles.modalIcon}
            src="/other-icons/thanks-icon.svg"
            alt="Thanks"
          />
        )}
        <p
          className={styles.modalDescription}
          style={{ whiteSpace: 'pre-line' }}>
          {userData.message}
        </p>
        <div className={styles.confirmCTAs}>
          {!userData.hideCancelButton && (
            <Button
              className={`${styles.popupBtn} compact mb-3`}
              variant="outline-primary"
              onClick={hideModal}>
              {t(lang, 'common')('close')}
            </Button>
          )}
          {userData.showSubmitButton && (
            <Button
              className={`${styles.popupBtn} compact mb-3`}
              onClick={() => {
                hideModal();
                userData?.confirmActionCallback();
              }}
              style={{
                backgroundColor: userData.submitButtonColor,
                color: userData.submitButtonColor && '#fff',
                borderColor: userData.submitButtonColor,
              }}>
              {userData.submitButton}
            </Button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SuccessMessagePopup;
