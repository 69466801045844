import ax from 'axios';
import { axios, cancelTokens, getAuthToken } from '.';

const createPodcast = async (payload) => {
  const url = `api/podcasts/`;
  const tokenHeaders = getAuthToken();
  const response = await axios.post(url, payload, { ...tokenHeaders });
  return response;
};

const createAudioSeries = async (payload) => {
  const url = `api/audio-series/`;
  const tokenHeaders = getAuthToken();
  const response = await axios.post(url, payload, { ...tokenHeaders });
  return response;
};

const importPodcast = async (payload) => {
  const url = `api/import-rss-feed/`;
  const tokenHeaders = getAuthToken();
  const response = await axios.post(url, payload, { ...tokenHeaders });
  return response;
};

const updatePodcast = async (payload, slug) => {
  const url = `api/podcasts/${slug}/`;
  const tokenHeaders = getAuthToken();
  const response = await axios.patch(url, payload, { ...tokenHeaders });
  return response;
};

const updateAudioSeries = async (payload, slug) => {
  const url = `api/audio-series/${slug}/`;
  const tokenHeaders = getAuthToken();
  const response = await axios.patch(url, payload, { ...tokenHeaders });
  return response;
};

const languages = async () => {
  const url = `api/languages/`;
  const response = await axios.get(url);
  return response;
};

const categories = async (isAuthRequired) => {
  const url = `api/categories/`;
  const tokenHeaders = isAuthRequired ? getAuthToken() : {};
  const response = await axios.get(url, { ...tokenHeaders });
  return response;
};

const podcastList = async (isAuthRequired, urlWithParams, tokenFromServer) => {
  let url = `api/podcasts/`;
  const tokenHeaders = getAuthToken(tokenFromServer);
  if (urlWithParams) url = urlWithParams;
  const token = isAuthRequired ? tokenHeaders : {};
  const response = await axios.get(url, { ...token });
  return response;
};

const allAudioList = async (isAuthRequired, urlWithParams, tokenFromServer) => {
  let url = `api/all-audios/`;
  const tokenHeaders = getAuthToken(tokenFromServer);
  if (urlWithParams) url = urlWithParams;
  const token = isAuthRequired ? tokenHeaders : {};
  const response = await axios.get(url, { ...token });
  return response;
};
const discoverList = async (isAuthRequired, queryParams, tokenFromServer) => {
  let url = `api/discover/`;
  const tokenHeaders = getAuthToken(tokenFromServer);
  if (queryParams) url = `${url}?${queryParams}`;
  const token = isAuthRequired ? tokenHeaders : {};
  const response = await axios.get(url, { ...token });
  return response;
};

const discoverPaginated = (config) => axios.get('/api/discover/', config);

const episodesPaginated = async (id, config) => {
  cancelTokens?.map((source) => {
    source.cancel({ isCancel: true });
  });
  cancelTokens.length = 0;
  const cancelToken = ax.CancelToken.source();
  cancelTokens.push(cancelToken);
  const response = await axios.get(`api/podcasts/${id}/episodes_list/`, {
    ...config,
    cancelToken: cancelToken.token,
  });
  return response;
};

const audiosPaginated = async (id, config) => {
  cancelTokens?.map((source) => {
    source.cancel({ isCancel: true });
  });
  cancelTokens.length = 0;
  const cancelToken = ax.CancelToken.source();
  cancelTokens.push(cancelToken);
  const response = await axios.get(`api/audio-series/${id}/episodes_list/`, {
    ...config,
    cancelToken: cancelToken.token,
  });
  return response;
};

const mySubscriptions = async () => {
  const url = `api/mysubscription/`;
  const tokenHeaders = getAuthToken();
  const response = await axios.get(url, { ...tokenHeaders });
  return response;
};

const userCountry = async () => {
  const url = `api/user-country/`;
  const response = await axios.get(url);
  return response;
};

const podcastDetail = async (id, isAuthRequired, tokenFromServer, params) => {
  const url = `api/podcasts/${id}/`;
  const tokenHeaders = getAuthToken();
  let token = isAuthRequired ? tokenHeaders : {};
  if (tokenFromServer) {
    token = { headers: { Authorization: `Token ${tokenFromServer}` } };
  }
  const response = await axios.get(url, { ...token, params });
  return response;
};

const audioSeriesDetail = async (
  id,
  isAuthRequired,
  tokenFromServer,
  params,
) => {
  const url = `api/audio-series/${id}/`;
  const tokenHeaders = getAuthToken();
  let token = isAuthRequired ? tokenHeaders : {};
  if (tokenFromServer) {
    token = { headers: { Authorization: `Token ${tokenFromServer}` } };
  }
  const response = await axios.get(url, { ...token, params });
  return response;
};

const getPublicPodcastRedirectUrl = async (queryParams = {}) => {
  const url = `api/get-redirect-url/`;
  const response = await axios.get(url, { params: queryParams });
  return response;
};

const openPodcastDetail = async (
  id,
  isAuthRequired,
  tokenFromServer,
  queryParams = {},
) => {
  const url = `api/open-podcast/${id}/`;
  const tokenHeaders = getAuthToken();
  let token = isAuthRequired ? tokenHeaders : {};
  if (tokenFromServer) {
    token = { headers: { Authorization: `Token ${tokenFromServer}` } };
  }
  const response = await axios.get(url, { ...token, params: queryParams });
  return response;
};

const podcastAnalytics = async (id, tokenFromServer) => {
  const url = `/api/podcast-analytics/${id}/`;
  let token = getAuthToken();
  if (tokenFromServer) {
    token = { headers: { Authorization: `Token ${tokenFromServer}` } };
  }
  const response = await axios.get(url, { ...token });
  return response;
};
const audioSeriesAnalytics = async (id, tokenFromServer) => {
  const url = `/api/audio-series-analytics/${id}/`;
  let token = getAuthToken();
  if (tokenFromServer) {
    token = { headers: { Authorization: `Token ${tokenFromServer}` } };
  }
  const response = await axios.get(url, { ...token });
  return response;
};

// const payout = async (slug) => {
//   const url = `api/request-payout/${slug}`;
//   const tokenHeaders = getAuthToken();
//   const response = await axios.get(url, { ...tokenHeaders });
//   return response;
// };

const payoutDashboard = async () => {
  const url = `api/request-payout/`;
  const tokenHeaders = getAuthToken();
  const response = await axios.get(url, { ...tokenHeaders });
  return response;
};

const productPayoutAmount = async () => {
  const url = `api/products-payout-amount/`;
  const tokenHeaders = getAuthToken();
  const response = await axios.get(url, { ...tokenHeaders });
  return response;
};

const deletePodcast = async (id) => {
  const url = `api/delete-podcast/${id}/`;
  const tokenHeaders = getAuthToken();
  const response = await axios.delete(url, { ...tokenHeaders });
  return response;
};
const deleteAudioSeries = async (id) => {
  const url = `api/delete-audio-series/${id}/`;
  const tokenHeaders = getAuthToken();
  const response = await axios.delete(url, { ...tokenHeaders });
  return response;
};

const getMumblerCommission = async () => {
  const url = `api/configurations/`;
  const response = await axios.get(url);
  return response;
};

const podcastCoupons = async (isAuthRequired, tokenFromServer, params) => {
  const url = 'api/coupons/';
  const tokenHeaders = getAuthToken();
  let token = isAuthRequired ? tokenHeaders : {};
  if (tokenFromServer) {
    token = { headers: { Authorization: `Token ${tokenFromServer}` } };
  }
  const response = await axios.get(url, { ...token, params });
  return response;
};

const verifyCoupon = async (payload) => {
  const url = 'api/verify-coupon/';
  const tokenHeaders = getAuthToken();
  return axios.post(url, payload, { ...tokenHeaders });
};

const getPodcastMetricDataFromJson = async (url) => {
  const response = await axios.get(url, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });
  return response;
};

const getEpisodeMetricsOverview = async (podcastSlug) => {
  const url = `api/episodes-metrics-overview/${podcastSlug}`;
  const tokenHeaders = getAuthToken();
  const response = await axios.get(url, { ...tokenHeaders });
  return response;
};

export {
  createPodcast,
  createAudioSeries,
  importPodcast,
  languages,
  categories,
  podcastList,
  allAudioList,
  discoverList,
  mySubscriptions,
  userCountry,
  podcastDetail,
  audioSeriesDetail,
  updatePodcast,
  updateAudioSeries,
  openPodcastDetail,
  podcastAnalytics,
  audioSeriesAnalytics,
  // payout,
  payoutDashboard,
  productPayoutAmount,
  deletePodcast,
  deleteAudioSeries,
  getMumblerCommission,
  discoverPaginated,
  episodesPaginated,
  audiosPaginated,
  podcastCoupons,
  verifyCoupon,
  getPublicPodcastRedirectUrl,
  getPodcastMetricDataFromJson,
  getEpisodeMetricsOverview
};
