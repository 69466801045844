import { axios, getAuthToken } from '.';

const editProfile = async (payload) => {
  const url = `api/auth/user/`;
  const tokenHeaders = getAuthToken();
  const response = await axios.patch(url, payload, { ...tokenHeaders });
  return response;
};

const getProfileData = async (withToken) => {
  const url = `api/auth/user/`;
  let token = getAuthToken();
  if (withToken) token = { headers: { Authorization: `Token ${withToken}` } };
  const response = await axios.get(url, { ...token });
  return response;
};

const deleteAccount = async () => {
  const url = `api/delete-account/`;
  const token = getAuthToken();
  const response = await axios.get(url, { ...token });
  return response;
};

const getUserData = async (withToken) => {
  const url = `api/gtm-data`;
  let token = getAuthToken();
  if (withToken) token = { headers: { Authorization: `Token ${withToken}` } };
  const response = await axios.get(url, { ...token });
  return response;
};

const checkCreator = async (domain) => {
  const url = `api/check-creatordomain/?domain=${domain}`;
  const token = getAuthToken();
  const response = await axios.get(url, { ...token });
  return response;
};

export { editProfile, getProfileData, deleteAccount, getUserData, checkCreator };
