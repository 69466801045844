import Cookies from 'js-cookie';

const stagingPrefix = 's_';
const isDevelopment = process.env.NEXT_PUBLIC_ENV !== 'production';

// for adding prefix to cookies related to staging
const customCookies = {
  get: (name, attributes) =>
    Cookies.get(`${isDevelopment ? stagingPrefix : ''}${name}`, attributes),
  set: (name, value, attributes) => {
    Cookies.set(
      `${isDevelopment ? stagingPrefix : ''}${name}`,
      value,
      attributes,
    );
  },
  remove: (name, attributes) => {
    Cookies.remove(`${isDevelopment ? stagingPrefix : ''}${name}`, attributes);
  },
  getJSON: () => Cookies.get(),
};

Object.setPrototypeOf(customCookies, Cookies);

export default customCookies;
