import React, { useEffect, useRef, useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import AudioPlayer from 'react-h5-audio-player';
import UserContext from 'context/UserContext';
import styles from '../Toast/Popup.module.scss';

const AudioPopup = ({
  show,
  onHide,
  episodeData,
  currentTime,
  jumpToSpecificTime,
}) => {
  const audioRef = useRef();
  const [userData, setUserData] = useContext(UserContext);
  const [playLoadSrc, setPlayLoadSrc] = useState('/other-icons/loading.svg');
  // const [ showModal, setShowModal ] = useState(false)
  useEffect(() => {
    if (userData.audioPlayStatus) audioRef.current?.audio?.current?.play();
    if (!userData.audioPlayStatus) audioRef.current?.audio?.current?.pause();
  }, [userData]);
  useEffect(() => {
    if (currentTime && currentTime > 2 && audioRef?.current) {
      audioRef.current.audio.current.currentTime = currentTime;
      if (userData.audioPlayStatus !== false)
        audioRef.current.audio.current.play();
    }
  }, [currentTime]);
  useEffect(() => {
    if (show) {
      if (
        audioRef?.current &&
        audioRef?.current?.audio?.current?.readyState === 4
      ) {
        audioRef.current.audio.current.currentTime = currentTime;
        setPlayLoadSrc('/other-icons/pause-icon.svg');
      } else {
        setPlayLoadSrc('/other-icons/loading.svg');
      }
    }
  }, [show]);
  const playMainAudio = () => {
    setUserData((prevState) => ({
      ...prevState,
      audioPlayStatus: true,
    }));
  };
  const pauseMainAudio = () => {
    setUserData((prevState) => ({
      ...prevState,
      audioPlayStatus: false,
    }));
  };
  const prevNextClickHandler = (clickedBtn) => {
    audioRef.current.audio.current.currentTime = 0;
    sessionStorage.removeItem('started');
    sessionStorage.removeItem('lastListenTime');
    sessionStorage.removeItem('apiHitted');
    setUserData((prevState) => ({
      ...prevState,
      isOnRefresh: false,
      reqForBtn: clickedBtn + new Date().getTime(),
    }));
  };
  const onSeekHandler = (audio, time) =>
    new Promise((resolve) => {
      jumpToSpecificTime(time);
      audioRef.current.audio.current.currentTime = time;
      resolve();
    });

  const onPlayHandler = () => {
    if (audioRef?.current?.audio?.current?.readyState === 4) {
      setPlayLoadSrc('/other-icons/pause-icon.svg');
    } else {
      setPlayLoadSrc('/other-icons/loading.svg');
    }
  };

  const onCanPlayHandler = () => {
    setPlayLoadSrc('/other-icons/pause-icon.svg');
  };
  return (
    <Modal
      className={`${styles.modalParent} ${styles.audioPopup} audio-popup`}
      backdropClassName={`${styles.audioBackdrop}`}
      show={show}
      onHide={onHide}
      size="md">
      <Modal.Body className={styles.modalBody}>
        <div className={styles.topHeading}>
          <h4 className={styles.podcastName}>{episodeData?.podcast_name}</h4>
          <img
            className={styles.closeIcon}
            src="/other-icons/caret-down.svg"
            onClick={onHide}
          />
        </div>
        <img src={episodeData?.cover_webp} className={styles.episodeCover} />

        <AudioPlayer
          src={episodeData?.url}
          ref={audioRef}
          muted
          mse={{
            onSeek: onSeekHandler,
          }}
          // onListen={e => console.log('e =', e)}
          onPlay={onPlayHandler}
          onClickPrevious={() => prevNextClickHandler('prev')}
          onClickNext={() => prevNextClickHandler('next')}
          onCanPlay={onCanPlayHandler}
          showSkipControls
          showJumpControls={false}
          customVolumeControls={[]}
          customAdditionalControls={[]}
          customIcons={{
            play: (
              <img
                className="player-play-icon"
                src="/other-icons/play-icon.svg"
                width="74"
                height="74"
                alt="Play"
                onClick={(e) => playMainAudio()}
              />
            ),
            pause: (
              <img
                className="player-pause-icon"
                src={playLoadSrc}
                width="74"
                height="74"
                alt="Pause"
                onClick={(e) => pauseMainAudio()}
              />
            ),
            previous: (
              <img
                src="/other-icons/previous-icon.svg"
                width="24"
                height="24"
                alt="Previous"
              />
            ),
            next: (
              <img
                src="/other-icons/next-icon.svg"
                width="24"
                height="24"
                alt="Previous"
              />
            ),
          }}
        />

        <div className={styles.episodeDetails}>
          <p className={styles.heading}>{episodeData?.episode_name}</p>
          <p className={styles.authorName}>{episodeData?.hosted_by}</p>
          <p className={styles.description} dangerouslySetInnerHTML={{ __html: episodeData?.description }}></p>
        </div>
      </Modal.Body>
    </Modal>
  );
};

AudioPopup.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  slug: PropTypes.string,
  title: PropTypes.string,
  episodeData: PropTypes.object,
  currentTime: PropTypes.number,
  jumpToSpecificTime: PropTypes.func,
};

export default AudioPopup;
