import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Router from 'next/router';
import { toast } from 'react-toastify';
import { gtmEvents } from 'utils/gtm.ts';
import UserContext from 'context/UserContext';
import Cookies from 'lib/js-cookie';
import CookiesOriginal from 'js-cookie';
import { getProfileData, editProfile } from 'apis/profile';
import routes from 'utils/routes';
import { translate as t } from 'utils/helpers';

const gtmDataTimeout = 1000 * 60;

const isProduction = process.env.NODE_ENV === 'production';
const googleTagManagerID = process.env.NEXT_PUBLIC_GTM_ID || '';
const stagingPrefix = 's_';
const isDevelopment = process.env.NEXT_PUBLIC_ENV !== 'production';

const UserProvider = ({
  children,
  token,
  avatar,
  avatar_webp,
  isEmailVerified,
  playerData,
  isMobile: mobileAgent,
}) => {
  const [userData, setUserData] = useState({
    token,
    avatar,
    avatar_webp,
    isEmailVerified,
    playerData,
  });

  const lang = Cookies.get('language');
  const [isMobile] = useState(true);

  const logout = () => {
    Cookies.remove('token', { path: '', domain: '.mumbler.io' });
    if (process.env.NEXT_PUBLIC_FRONTEND_URL === 'http://localhost:3000') {
      Cookies.remove('token', { path: '/' });
      Cookies.remove('podcaster', { path: '/' });
      Cookies.remove('avatar', { path: '/' });
      Cookies.remove('avatar_webp', { path: '/' });
      Cookies.remove('podcast_id');
      Cookies.remove('isEmailVerified', { path: '/' });
      Cookies.remove('playerData', { path: '/' });
      Cookies.remove('subscriptionConfirmation', { path: '/' });
      Cookies.remove('onBoarding', { path: '/' });
      Cookies.remove('creator_id', { path: '/' });
      Cookies.remove('soa_state', { path: '/' });
      Cookies.remove('spotifyShowUri', { path: '/' });
      Cookies.remove('soa_isSoaLinked', { path: '/' });
      Cookies.remove('soa_code', { path: '/' });
    } else {
      Cookies.remove('token', { path: '/', domain: '.mumbler.io' });
      Cookies.remove('podcaster', { path: '/', domain: '.mumbler.io' });
      Cookies.remove('avatar', { path: '/', domain: '.mumbler.io' });
      Cookies.remove('avatar_webp', { path: '/', domain: '.mumbler.io' });
      Cookies.remove('podcast_id');
      Cookies.remove('isEmailVerified', { path: '/', domain: '.mumbler.io' });
      Cookies.remove('playerData', { path: '/', domain: '.mumbler.io' });
      Cookies.remove('subscriptionConfirmation', {
        path: '/',
        domain: '.mumbler.io',
      });
      Cookies.remove('onBoarding', { path: '/', domain: '.mumbler.io' });
      Cookies.remove('creator_id', { path: '/', domain: '.mumbler.io' });
      Cookies.remove('soa_state', {
        path: '/',
        domain: '.mumbler.io',
      });
      Cookies.remove('spotifyShowUri', {
        path: '/',
        domain: '.mumbler.io',
      });
      Cookies.remove('soa_isSoaLinked', {
        path: '/',
        domain: '.mumbler.io',
      });
      Cookies.remove('soa_code', {
        path: '/',
        domain: '.mumbler.io',
      });
    }

    // remove third party cookies.
    CookiesOriginal.remove('_fprom_tid', { path: '/', domain: '.mumbler.io' });
    CookiesOriginal.remove('_fprom_ref', { path: '/', domain: '.mumbler.io' });

    setUserData({
      token: null,
      avatar: null,
      avatar_webp: null,
      podcast_id: null,
      country: null,
      language: null,
      email: null,
      audioStatus: 'stop',
      isEmailVerified: true,
      play: false,
      episodeDetail: {
        url: '',
      },
      metricsSpecialAccess: null,
      insertionSpecialAccess: null,
      showPlanLimitExceedBanner: false,
      subscription: {},
      isSoaLinked: null,
      giveSpotifyAccess: null,
    });
    Router.push(routes.login(lang));
  };

  useEffect(() => {
    if (isProduction && googleTagManagerID.length) {
      // setInterval(() => {
      tractUserData();
      // }, gtmDataTimeout);
    }
    if (token) {
      getData();
    }
  }, []);

  const cancelPopupForLimit = async (payload, action, plan) => {
    const response = await editProfile(payload);
    if (response.status === 200) {
      if (action === 'confirm') {
        if (plan === 'PLUS') {
          window?.Froged('open');
        } else {
          Router.push(routes.plans);
        }
      }
    }
  };

  const updateImportRssFailed = async (payload) => {
    const response = await editProfile(payload);
    if (response.status === 200) {
      setUserData((prevState) => ({
        ...prevState,
        importPodcast: {
          showModal: true,
          title: t(lang, 'podcast')('rssImportFailed'),
          message: t(lang, 'podcast')('rssImportFailedDescription'),
          showIcon: false,
          showSubmitButton: true,
          submitButton: t(lang, 'podcast')('contact'),
          confirmActionCallback: () => {
            window?.Froged('open');
          },
          closeActionCallback: () => {},
        },
      }));
    }
  };

  const closeEditImportPopup = async () => {
    const payload = {
      rss_feed_data: null,
    };
    await editProfile(payload);
  };

  const getData = async () => {
    const response = await getProfileData();
    if (response?.status === 200) {
      const cookiesJson = document?.cookie?.split('; '); // Get all cookies as an object

      // Filter cookies with the specified name
      const matchingCookies = cookiesJson.filter((cookie) => {
        const [name] = cookie.split('=');
        return name === `${isDevelopment ? stagingPrefix : ''}podcaster`;
      });

      // Check if there are multiple cookies with the same name
      if (matchingCookies.length >= 2) {
        // set all cookies to past past to delete it

        document?.cookie?.split(';').forEach((c) => {
          document.cookie = c
            .replace(/^ +/, '')
            .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
        });

        // clearing local storage
        localStorage.clear();
        // Router.push(routes.login(lang));
        // Log out the user (perform your logout logic here)
        toast.success(t(lang, 'common')('logoutForSecurityPurpose'));

        logout();

        return false;
      }
      if (
        process.env.NEXT_PUBLIC_APP_FRONTEND_URL === 'http://localhost:3000'
      ) {
        Cookies.set('avatar', response?.data?.avatar, {
          expires: 40,
          path: '/',
        });
        Cookies.set('avatar_webp', response?.data?.avatar_webp, {
          expires: 40,
          path: '/',
        });
        Cookies.set('podcaster', response?.data?.pk, {
          expires: 40,
          path: '/',
        });
        Cookies.set(
          'creator_redirect_url',
          response?.data?.creator_redirect_url,
          {
            expires: 40,
            path: '/',
          },
        );
      } else {
        Cookies.set('avatar', response?.data?.avatar, {
          expires: 40,
          path: '/',
          domain: '.mumbler.io',
        });
        Cookies.set('avatar_webp', response?.data?.avatar_webp, {
          expires: 40,
          path: '/',
          domain: '.mumbler.io',
        });
        Cookies.set('podcaster', response?.data?.pk, {
          expires: 40,
          path: '/',
          domain: '.mumbler.io',
        });
        Cookies.set(
          'creator_redirect_url',
          response?.data?.creator_redirect_url,
          {
            expires: 40,
            path: '/',
            domain: '.mumbler.io',
          },
        );
      }
      let tempData;

      if (
        response?.data?.plan_subscribers_limit_exceed ||
        response?.data?.plan_plays_limit_exceed ||
        response?.data?.plan_paid_subscribers_limit_exceed
      ) {
        const payload = {};
        if (response?.data?.plan_plays_limit_exceed) {
          payload.plan_plays_limit_exceed = false;
        }
        if (response?.data?.plan_subscribers_limit_exceed) {
          payload.plan_subscribers_limit_exceed = false;
        }
        if (response?.data?.plan_paid_subscribers_limit_exceed) {
          payload.plan_paid_subscribers_limit_exceed = false;
        }

        // tempData = {
        //   showModal: true,
        //   title: t(lang, 'common')('congratulations'),
        //   message: t(
        //     lang,
        //     'settings',
        //   )('warningMessageToShowUserExceedPlanLimits'),
        //   showIcon: false,
        //   showSubmitButton: true,
        //   submitButton: t(lang, 'settings')('goToPlans'),
        //   confirmActionCallback: () => {
        //     cancelPopupForLimit(payload, 'confirm');
        //   },
        //   closeActionCallback: () => {
        //     cancelPopupForLimit(payload, 'cancel');
        //   },
        // };

        tempData = {
          planLimitPopup: {
            showModal: true,
            title: t(lang, 'common')('congratulations'),
            planLimitExceedName: response?.data?.subscription?.name,
            showSubmitButton: true,
            confirmActionCallback: () => {
              cancelPopupForLimit(
                payload,
                'confirm',
                response?.data?.subscription?.name,
              );
            },
            closeActionCallback: () => {
              cancelPopupForLimit(
                payload,
                'cancel',
                response?.data?.subscription?.name,
              );
            },
          },
        };
      }

      if (
        response?.data?.rss_feed_data &&
        !response?.data?.rss_feed_importing
      ) {
        closeEditImportPopup();
        setUserData((prevState) => ({
          ...prevState,
          importPodcast: {
            showModal: true,
            title: t(lang, 'podcast')('almostThere'),
            message: t(lang, 'podcast')('importSuccessMessage'),
            showIcon: false,
            showSubmitButton: true,
            submitButton: t(lang, 'podcast')('editPodcast'),
            confirmActionCallback: () => {
              Router.push(
                routes.editPodcastPage(response?.data?.rss_feed_data),
              );
            },
            closeActionCallback: () => {},
          },
        }));
      } else if (response?.data?.rss_feed_import_failed) {
        const payload = {
          rss_feed_import_failed: false,
        };
        updateImportRssFailed(payload);
      }
      setUserData((prevState) => ({
        ...prevState,
        avatar: response?.data?.avatar,
        avatar_webp: response?.data?.avatar_webp,
        country: response?.data?.country,
        language: response?.data?.language,
        currency: response?.data?.currency,
        isEmailVerified: response?.data?.is_email_verified,
        isCardExpired: response?.data?.is_card_expired,
        creator: response?.data?.creator,
        pro_plan_user: response?.data?.pro_plan_user,
        redirect_to_plan: response?.data?.redirect_to_plan,
        creator_redirect_url: response?.data?.creator_redirect_url,
        user_type: response?.data?.type,
        user_id: response?.data?.pk,
        email: response?.data?.email,
        stripe_payment_method_id: response?.data?.stripe_payment_method_id,
        showPlanLimitExceedBanner:
          response?.data?.show_plan_limit_exceed_banner,
        subscription: response?.data?.subscription,
        metricsSpecialAccess: response?.data?.metrics_special_access,
        insertionSpecialAccess: response?.data?.insertion_special_access,
        isSoaLinked: response?.data?.is_soa_linked,
        giveSpotifyAccess: response?.data?.give_spotify_access,
        ...tempData,
      }));
    } else {
      logout();
    }
  };

  const tractUserData = () => gtmEvents.userData();

  return (
    <UserContext.Provider value={[userData, setUserData, isMobile]}>
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.any.isRequired,
  token: PropTypes.string,
  avatar: PropTypes.string,
  isEmailVerified: PropTypes.bool,
  isMobile: PropTypes.bool,
  playerData: PropTypes.object,
};

export default UserProvider;
