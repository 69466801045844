import cookies from 'next-cookies';

// return object with keys with removed stagingPrefix
// ex. { s_token: "..." } -> { token: "..." }
const cookiesCustom = (context, options) => {
  const stagingPrefix = 's_'
  const isDevelopment = process.env.NEXT_PUBLIC_ENV !== 'production';
  const allCookies = cookies(context, options);

  if (!isDevelopment) return allCookies;

  const updatedCookies = {};
  for (let key in allCookies) {
    if (key.startsWith(stagingPrefix)) {
      const newKey = key.replace(stagingPrefix, '')
      updatedCookies[newKey] = allCookies[key];
    }
  }

  return updatedCookies;
};

export default cookiesCustom;
