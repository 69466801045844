import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import Button from 'react-bootstrap/Button';
import Router from 'next/router';
import UserContext from 'context/UserContext';
import { languages, supportedLanguages, translate as t } from 'utils/helpers';
import useLang from 'context/LanguageContext';
import { countries as getCountriesAPI } from 'apis/countries';
import { customSelectStyle } from 'components/Utilities';
import { editProfile } from 'apis/profile';
import styles from './Popup.module.scss';

const CountrySelectionPopup = () => {
  const [userData, setUserData] = useContext(UserContext);
  const [countries, setCountries] = useState([]);
  const { lang, setLang } = useLang();
  const hideModal = () => {};
  const { control, errors, setValue, handleSubmit, register } = useForm({});

  useEffect(() => {
    if (
      userData?.token &&
      ((!userData?.country && typeof userData.country !== 'undefined') ||
        (!userData?.language && typeof userData.language !== 'undefined'))
    ) {
      getCountries();
    }
  }, [userData]);

  const getCountries = async () => {
    const response = await getCountriesAPI();
    if (response?.data) {
      const countryList = response?.data.map((item) => ({
        value: item.id,
        label: lang === 'es' ? item.name_es : item.name,
      }));
      setCountries(countryList);
    }
  };

  const updateCountry = async (data) => {
    const formData = new FormData();
    formData.append('country', data.country);
    formData.append('language', data.language);
    formData.append(
      'consents_to_marketing_emails',
      data.consents_to_marketing_emails,
    );
    const response = await editProfile(formData);
    if (response?.status === 200) {
      setUserData((prevState) => ({
        ...prevState,
        country: response?.data?.country,
        currency: response?.data?.currency,
        language: response?.data?.language,
      }));
    }
  };

  const languageChangeHandler = (event) => {
    setValue('language', event?.value, { shouldValidate: true });
    const urlArray = window?.location?.pathname?.substring(1)?.split('/');
    const currentLanguage = urlArray?.[0];
    if (supportedLanguages.includes(currentLanguage)) {
      urlArray.shift();
      const newURL =
        [event?.value, ...urlArray].join('/') + window?.location?.search;
      Router.push(`/${newURL}`);
    } else {
      setLang(event?.value);
    }
  };

  const countryChangeHandler = (event) => {
    setValue('country', event?.value, { shouldValidate: true });
  };

  useEffect(() => {
    const country = countries.find(({ value }) => value === userData?.country);
    if (country) {
      setValue('country', country.value, { shouldValidate: true });
    }
    const language = languages.find(
      ({ value }) => userData?.language === value,
    );
    if (language) {
      setValue('language', language?.value, { shouldValidate: true });
    }
  }, [countries, userData]);

  return (
    <Modal
      className={styles.modalParent}
      show={
        userData?.token &&
        ((!userData?.country && typeof userData.country !== 'undefined') ||
          (!userData?.language && typeof userData.language !== 'undefined'))
      }
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={hideModal}>
      <Modal.Body className={styles.modalBody}>
        <h4 className={styles.modalTitle}>
          {t(lang, 'privacyPolicy')('welcomeMessage')}
        </h4>
        <p className={styles.modalDescription}>
          {t(lang, 'registerLogin')('youAreAlmostThere')}
        </p>
        <Form
          className="form"
          noValidate
          onSubmit={handleSubmit(updateCountry)}>
          <Form.Group>
            <Controller
              control={control}
              name="language"
              defaultValue=""
              rules={{ required: t(lang, 'podcast')('podcastLanguageError') }}
              render={() => (
                <Select
                  className={`search-select-wrap ${
                    errors.language ? 'invalid-input' : ''
                  }`}
                  classNamePrefix="search-select"
                  styles={customSelectStyle}
                  noOptionsMessage={() => t(lang, 'podcast')('noOptionFound')}
                  placeholder={t(lang, 'podcast')('podcastLanguagePlaceholder')}
                  name="language"
                  onChange={languageChangeHandler}
                  defaultValue={languages.find(
                    ({ value }) => userData?.language === value,
                  )}
                  options={languages}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#0d122a',
                      primary75: '#0d122a',
                      primary: '#0d122a',
                    },
                  })}
                />
              )}
            />
          </Form.Group>
          <div className="invalid-feedback custom-error">
            {errors?.language && errors?.language?.message}
          </div>

          <Form.Group>
            <Controller
              control={control}
              name="country"
              defaultValue=""
              rules={{ required: t(lang, 'account')('countryError') }}
              render={() => (
                <Select
                  className={`search-select-wrap ${
                    errors.country ? 'invalid-input' : ''
                  }`}
                  classNamePrefix="search-select"
                  styles={customSelectStyle}
                  noOptionsMessage={() => t(lang, 'podcast')('noOptionFound')}
                  placeholder={t(lang, 'account')('country')}
                  name="country"
                  onChange={countryChangeHandler}
                  defaultValue={countries?.find(
                    ({ value }) => value === userData?.country,
                  )}
                  options={countries}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#0d122a',
                      primary75: '#0d122a',
                      primary: '#0d122a',
                    },
                  })}
                />
              )}
            />
          </Form.Group>
          <Form.Group>
            <Form.Check
              className="custom-checkbox"
              custom
              type="checkbox"
              id="consents_to_marketing_emails"
              ref={register()}
              label={t(lang, 'account')('newsLetter')}
              name="consents_to_marketing_emails"
            />
          </Form.Group>
          <Button
            type="submit"
            className={`${styles.popupBtn} compact`}
            variant="outline-primary">
            {t(lang, 'common')('save')}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CountrySelectionPopup;
