import { createContext, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  useEffect(() => {
    const handleRouteChangeStart = () => {
      setIsPageLoaded(false);
    };

    const handleRouteChangeComplete = () => {
      setIsPageLoaded(true);
    };

    window.addEventListener('beforeunload', handleRouteChangeStart);
    window.addEventListener('load', handleRouteChangeComplete);

    // Cleanup event listeners on unmount
    return () => {
      window.removeEventListener('beforeunload', handleRouteChangeStart);
      window.removeEventListener('load', handleRouteChangeComplete);
    };
  }, []);

  return (
    <LoadingContext.Provider value={{ isPageLoaded }}>
      {children}
    </LoadingContext.Provider>
  );
};

LoadingProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export const useLoading = () => useContext(LoadingContext);
