import React from 'react';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';

const Toast = (props) => (
  <ToastContainer
    position="bottom-left"
    autoClose={5000}
    hideProgressBar={true}
    newestOnTop={false}
    closeOnClick={false}
    rtl={false}
    pauseOnFocusLoss={false}
    draggable
    pauseOnHover>
    {props.message}
  </ToastContainer>
);

Toast.propTypes = {
  message: PropTypes.string.isRequired,
};

export default Toast;
