import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'lib/js-cookie';
import { supportedLanguages } from 'utils/helpers';
export const LanguageContext = React.createContext({
  lang: 'en',
  setLang: () => {},
  podcastLanguages: [],
  setPodcastLanguages: () => {},
});

export const LanguageProvider = ({ children, cookieLang }) => {
  const [lang, setLang] = useState(cookieLang);
  const [podcastLanguages, setPodcastLanguages] = useState([]);

  useEffect(() => {
    if (supportedLanguages.includes(lang)) {
      if(process.env.NEXT_PUBLIC_APP_FRONTEND_URL === 'http://localhost:3000') {
        Cookies.set('language', lang, {expires: 40, path: '/'});
      }else{
        Cookies.set('language', lang, {expires: 40, path: '/', domain: '.mumbler.io'});
      }
    }
  }, [lang]);

  return (
    <LanguageContext.Provider
      value={{ lang, setLang, podcastLanguages, setPodcastLanguages }}>
      {children}
    </LanguageContext.Provider>
  );
};

LanguageProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  cookieLang: PropTypes.string,
};

const useLang = () => useContext(LanguageContext);

export default useLang;
