import React, { useEffect } from 'react';
import Router from 'next/router';
import Cookies from 'lib/js-cookie';
import { supportedLanguages } from 'utils/helpers';
import useLang from 'context/LanguageContext';
import { languages } from '../../apis/podcast';

const LanguageContextHandler = () => {
  const { setLang, setPodcastLanguages } = useLang();

  const getLanguages = async () => {
    const response = await languages();
    if (response?.status === 200) {
      setPodcastLanguages(response?.data);
    }
  };

  useEffect(() => {
    // getLanguages();
    Router.events.on('routeChangeComplete', (url) => {
      const language = url.substring(1)?.split('/')?.[0];
      if (supportedLanguages.includes(language)) {
        setLang(language);
        if(process.env.NEXT_PUBLIC_APP_FRONTEND_URL === 'http://localhost:3000') {
          Cookies.set('language', language, {expires: 40, path: '/'});
        }else{
          Cookies.set('language', language, {expires: 40, path: '/', domain: '.mumbler.io'});
        }
      }
    });
  }, []);
  return null;
};

export default LanguageContextHandler;
