// components/LoadingOverlay.js
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useLoading } from 'context/LoadingContext';

const LoadingOverlay = () => {
  const { isPageLoaded } = useLoading();

  if (isPageLoaded) return null;

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        zIndex: 1301, // higher than other components
      }}>
      <CircularProgress style={{ color: '#0d122a' }} thickness={3} size={30} />
    </Box>
  );
};

export default LoadingOverlay;
