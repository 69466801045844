import ax from 'axios';
import {axios, cancelTokens, getAuthToken} from '.';

const generateUrlUploadFile = async (payload) => {
  const tokenHeaders = getAuthToken();
  const url = `api/generate-url-upload-file`;
  const response = await axios.post(url, payload, { ...tokenHeaders });
  return response;
};
const generateUrlUploadPromo = async (payload) => {
  const tokenHeaders = getAuthToken();
  const url = `api/generate-url-upload-promo`;
  const response = await axios.post(url, payload, { ...tokenHeaders });
  return response;
};

const uploadEpisodeToS3 = async (url, payload, config) => {
  if (process.env.NEXT_PUBLIC_API_URL.includes('localhost'))
    return { status: 200 };
  return axios.post(url, payload, { ...config });
};

const createEpisode = async (payload) => {
  const tokenHeaders = getAuthToken();
  const url = `api/episodes/`;
  const response = await axios.post(url, payload, { ...tokenHeaders });
  return response;
};

const createAudioSeriesEpisode = async (payload) => {
  const tokenHeaders = getAuthToken();
  const url = `api/audio-series-episodes/`;
  const response = await axios.post(url, payload, { ...tokenHeaders });
  return response;
};

const deleteEpisode = async (id, query = '') => {
  const tokenHeaders = getAuthToken();
  let url = `/api/episodes/${id}/`;
  if (query) {
    url = `${url}?${query}`;
  }
  const response = await axios.delete(url, { ...tokenHeaders });
  return response;
};

const deleteAudioSeriesEpisode = async (id, query = '') => {
  const tokenHeaders = getAuthToken();
  let url = `/api/audio-series-episodes/${id}/`;
  if (query) {
    url = `${url}?${query}`;
  }
  const response = await axios.delete(url, { ...tokenHeaders });
  return response;
};

const updateEpisode = async (id, payload, query = '') => {
  const tokenHeaders = getAuthToken();
  let url = `api/episodes/${id}/`;
  if (query) {
    url = `${url}?${query}`;
  }
  const response = await axios.patch(url, payload, { ...tokenHeaders });
  return response;
};

const updateAudioSeriesEpisode = async (id, payload, query = '') => {
  const tokenHeaders = getAuthToken();
  let url = `api/audio-series-episodes/${id}/`;
  if (query) {
    url = `${url}?${query}`;
  }
  const response = await axios.patch(url, payload, { ...tokenHeaders });
  return response;
};

const episodeList = async (idOrUrl, isAuthRequired, param, URL) => {
  cancelTokens?.map((source) => {
    source.cancel({ isCancel: true });
  });
  cancelTokens.length = 0;
  const cancelToken = ax.CancelToken.source();
  cancelTokens.push(cancelToken);
  let url = `api/podcasts/${idOrUrl}/episodes_list/?${param}`;
  if (URL) {
    url = idOrUrl;
  }
  const token = isAuthRequired ? getAuthToken() : {};
  const response = await axios.get(url, {
    ...token,
    cancelToken: cancelToken.token,
  });
  return response;
};

const audioSeriesEpisodeList = async (idOrUrl, isAuthRequired, param) => {
  cancelTokens?.map((source) => {
    source.cancel({ isCancel: true });
  });
  cancelTokens.length = 0;
  const cancelToken = ax.CancelToken.source();
  cancelTokens.push(cancelToken);
  const url = `api/audio-series/${idOrUrl}/episodes_list/?${param}`;
  const token = isAuthRequired ? getAuthToken() : {};
  const response = await axios.get(url, {
    ...token,
    cancelToken: cancelToken.token,
  });
  return response;
};

const episodeDetail = async (id, isAuthRequired, t = '', query = '') => {
  let url = `/api/episodes/${id}/`;
  if (query) {
    url = `${url}?${query}`;
  }
  const token = isAuthRequired ? getAuthToken(t) : {};
  const response = await axios.get(url, { ...token });
  return response;
};

const audioSeriesEpisodeDetail = async (
  id,
  isAuthRequired,
  t = '',
  query = '',
) => {
  let url = `/api/audio-series-episodes/${id}/`;
  if (query) {
    url = `${url}?${query}`;
  }
  const token = isAuthRequired ? getAuthToken(t) : {};
  const response = await axios.get(url, { ...token });
  return response;
};

const openEpisodeDetail = async (id, isAuthRequired, t = '', query = '') => {
  let url = `/api/open-episode/${id}/`;
  if (query) {
    url = `${url}?${query}`;
  }
  const token = isAuthRequired ? getAuthToken(t) : {};
  const response = await axios.get(url, { ...token });
  return response;
};

const episodePromoDelete = async (slug, isAuthRequired, t = '', query = '') => {
  let url = `/api/episode-promo-delete/${slug}/`;
  if (query) {
    url = `${url}?${query}`;
  }
  const token = isAuthRequired ? getAuthToken(t) : {};
  const response = await axios.get(url, { ...token });
  return response;
};

const podcastEpisodePlay = async (id) => {
  const url = `api/podcast-episode-play/${id}/`;
  const tokenHeaders = getAuthToken();
  let config = { ...tokenHeaders };
  if (tokenHeaders?.headers?.Authorization === 'Token undefined') {
    config = {};
  }
  const response = await axios.get(url, config);
  return response;
};

const audioSeriesEpisodePlay = async (id) => {
  const url = `api/audio-series-episode-play/${id}/`;
  const tokenHeaders = getAuthToken();
  let config = { ...tokenHeaders };
  if (tokenHeaders?.headers?.Authorization === 'Token undefined') {
    config = {};
  }
  const response = await axios.get(url, config);
  return response;
};

const episodeAnalytics = async (id, tokenFromServer, query) => {
  let url = `/api/episode-analytics/${id}/`;
  if (query) {
    url = `${url}?${query}`;
  }
  let token = getAuthToken();
  if (tokenFromServer) {
    token = { headers: { Authorization: `Token ${tokenFromServer}` } };
  }
  const response = await axios.get(url, { ...token });
  return response;
};

const audioSeriesEpisodeAnalytics = async (id, tokenFromServer, query) => {
  let url = `/api/audio-series-episode-analytics/${id}/`;
  if (query) {
    url = `${url}?${query}`;
  }
  let token = getAuthToken();
  if (tokenFromServer) {
    token = { headers: { Authorization: `Token ${tokenFromServer}` } };
  }
  const response = await axios.get(url, { ...token });
  return response;
};

const otherEpisodes = async (id, query) => {
  let url = `api/other-episodes/${id}/`;
  if (query) {
    url = `${url}?${query}`;
  }
  const tokenHeaders = getAuthToken();
  let config = { ...tokenHeaders };
  if (tokenHeaders?.headers?.Authorization === 'Token undefined') {
    config = {};
  }
  const response = await axios.get(url, config);
  return response;
};

const checkTranscriptionCredits = async (params) => {
  const url = `api/check-transcription-credits/`;

  const tokenHeaders = getAuthToken();
  const config = { ...tokenHeaders, params };
  const response = await axios.get(url, config);
  return response;
};

const createEpisodeTranscript = async (payload) => {
  const tokenHeaders = getAuthToken();
  const url = `api/create-episode-transcript/`;
  const response = await axios.post(url, payload, { ...tokenHeaders });
  return response;
};


const episodeEvolutionDownloadMetrics = async (id, query = '') => {
  let url = `/api/evolution-downloads-metrics/${id}/`;
  if (query) {
    url = `${url}?${query}`;
  }
  const token = getAuthToken();
  const response = await axios.get(url, { ...token });
  return response;
};


const episodePlayerDownloadsMetrics = async (id, query = '') => {
  let url = `/api/player-downloads-metrics/${id}/`;
  if (query) {
    url = `${url}?${query}`;
  }
  const token = getAuthToken();
  const response = await axios.get(url, { ...token });
  return response;
};

const episodeOsDownloadsMetrics = async (id, query = '') => {
  let url = `/api/os-downloads-metrics/${id}/`;
  if (query) {
    url = `${url}?${query}`;
  }
  const token = getAuthToken();
  const response = await axios.get(url, { ...token });
  return response;
};

const episodeDeviceDownloadsMetrics = async (id, query = '') => {
  let url = `/api/device-downloads-metrics/${id}/`;
  if (query) {
    url = `${url}?${query}`;
  }
  const token = getAuthToken();
  const response = await axios.get(url, { ...token });
  return response;
};

export {
  generateUrlUploadFile,
  generateUrlUploadPromo,
  uploadEpisodeToS3,
  createEpisode,
  updateEpisode,
  episodeList,
  audioSeriesEpisodeList,
  episodeDetail,
  audioSeriesEpisodeDetail,
  openEpisodeDetail,
  episodePromoDelete,
  podcastEpisodePlay,
  audioSeriesEpisodePlay,
  episodeAnalytics,
  audioSeriesEpisodeAnalytics,
  deleteEpisode,
  updateAudioSeriesEpisode,
  createAudioSeriesEpisode,
  deleteAudioSeriesEpisode,
  otherEpisodes,
  checkTranscriptionCredits,
  createEpisodeTranscript,
  episodeEvolutionDownloadMetrics,
  episodePlayerDownloadsMetrics,
  episodeOsDownloadsMetrics,
  episodeDeviceDownloadsMetrics,
};
