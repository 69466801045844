import en from 'utils/translations/en';
import es from 'utils/translations/es';

export const supportedLanguages = ['en', 'es'];
export const supportedDomains = ['discover'];

export const reservedNames = ['app', 'dev-app', 'staging', 'staging-app'];

export const graphColor = [
  '#6ae396',
  '#03b6eb',
  '#02cac5',
  '#03d5b0',
  '#008080',
  '#2f5f98',
  '#A8D5BA',
  '#4682B4',
  '#FFD700',
  '#FFDB58',
  '#FFA07A',
  '#FF8C00',
  '#FF7F50',
  '#E6E6FA',
  '#B57EDC',
];

export const getSubdomain = () => {
  const host = window?.location?.host;
  const arr = host?.split('.').slice(0, host?.includes('localhost') ? -1 : -2);
  if (arr?.length > 0 && !arr[0].includes('dev-app')) {
    return arr;
  }
};
export const translate = (language, nameSpace) => {
  const lang = language === 'es' ? es[nameSpace] : en[nameSpace];

  return (keyword, params) => {
    let translation = lang?.[keyword];
    if (params && typeof translation === 'function') {
      translation = translation({ ...params });
    }
    if (!translation) {
      return '';
    }
    return translation;
  };
};

export const languages = [
  { label: 'English', value: 'en' },
  { label: 'Español', value: 'es' },
];

export const getCurrency = (currency = 'usd') => {
  switch (currency?.toLowerCase()) {
    case 'usd':
      return '$';
    case 'eur':
      return '€';
    default:
      return '$';
  }
};

export function formatNumber(number) {
  let n = Number(number);
  if (n % 1 !== 0) n = n?.toFixed(2);
  return n;
}

export function numToSignedStr(number) {
  return `${number < 0 ? '' : '+'}${number}`;
}